<template>
  <div class="qty-select">
    <label for="qty-select-input" class="qty-select-label">{{
      countPerson.field_name || "Кол-во персон"
    }}</label>
    <div class="qty-select-box">
      <button
        class="qty-select-minus"
        type="button"
        @click.prevent="minus"
      ></button>
      <input
        id="qty-select-input"
        v-model="modelValue"
        v-bind="$attrs"
        v-on="$listeners"
        class="qty-select-input"
        type="text"
        readonly
      />
      <button
        class="qty-select-plus"
        type="button"
        @click.prevent="plus"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: {
      type: null,
      default: 1,
    },
  },

  computed: {
    fieldsAddonsSettings: {
      get() {
        return this.$store.getters["app/fieldsAddonsSettings"] || [];
      },
    },
    countPerson: {
      get() {
        return (
          this.fieldsAddonsSettings.find(function (item) {
            return item.field === "count_person";
          }) || {}
        );
      },
    },
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  created() {
    this.modelValue = Number(this.value) || 1;
  },

  methods: {
    minus() {
      if (this.modelValue <= 0) return;
      this.modelValue--;
    },

    plus() {
      this.modelValue++;
    },
  },
};
</script>

<style lang="scss" scoped>
.qty-select {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.qty-select-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.qty-select-label {
  font-size: 16px;
  line-height: 1;
  margin: 0 0 8px;
  color: #333;
  font-weight: 300;

  .theme--dark & {
    color: #fff;
  }
}

.qty-select-input {
  width: 50px;
  background: transparent;
  border: transparent;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #111;

  .theme--dark & {
    color: #fff;
  }

  &:focus {
    outline: none;
  }
}

.qty-select-minus,
.qty-select-plus {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  background: var(--v-primary-base);
  border: transparent;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 12px;
    background: #fff;
  }

  &::after {
    transform: rotate(90deg);
  }
}

.qty-select-minus::before {
  display: none;
}
</style>
