<template>
  <v-container class="pt-5">
    <v-form ref="form" lazy-validation>
      <section v-if="isDelivery" class="mb-10">
        <v-row v-if="addresses && addresses.length" align="center">
          <v-col>
            <div class="heading mb-2">Выбрать сохраненный адрес</div>
          </v-col>
          <!-- <v-col cols="auto">
            <v-btn
              text
              color="primary"
              class="clear-button px-0"
              @click="clearCart"
              :loading="clearButtonLoading"
              :disabled="cartIsEmpty"
            >
              <v-icon class="mr-1">{{icons.mdiDotsVertical}}</v-icon>
              Указать на карте
            </v-btn>
          </v-col>-->
        </v-row>

        <template v-if="addresses && addresses.length">
          <v-select
            class="mb-6"
            label="Мои сохраненные адреса"
            v-model="address"
            :items="addresses"
            outlined
            hide-details
            @change="addressSelect"
          >
            <template v-slot:append>
              <v-icon color="secondary">{{ icons.mdiDotsVertical }}</v-icon>
            </template>
          </v-select>
        </template>

        <v-col v-if="fields.street" class="mb-6" @click="SET_ACTIVE(true)">
          <v-row>
            <div class="d-flex heading">
              {{ fields.street }}, {{ fields.house }}
              <v-icon>{{ icons.mdiChevronRight }}</v-icon>
            </div>
          </v-row>

          <v-row>
            <span class="extended-address">{{ extendedAddress }}</span>
          </v-row>
        </v-col>

        <v-row v-else>
          <v-col>
            <div class="d-flex heading mb-4" @click="SET_ACTIVE(true)">
              Указать адрес на карте
              <v-icon>{{ icons.mdiChevronRight }}</v-icon>
            </div>
          </v-col>
        </v-row>

        <div v-if="!address" class="private-house mb-4">
          <v-checkbox v-model="privateHouse" label="Частный дом" dense />
        </div>

        <div class="fields mb-6">
          <v-text-field
            v-if="
              additionalFields && additionalFields.pod == 2 && !privateHouse
            "
            label="Подъезд"
            v-model="pod"
            outlined
            hide-details
            clearable
            type="number"
          />

          <v-text-field
            v-if="
              additionalFields && additionalFields.domofon == 2 && !privateHouse
            "
            label="Домофон"
            v-model="domofon"
            outlined
            hide-details
            clearable
            type="number"
          />

          <v-text-field
            v-if="additionalFields && additionalFields.et == 2 && !privateHouse"
            label="Этаж"
            v-model="et"
            outlined
            hide-details
            clearable
            type="number"
          />

          <v-text-field
            v-if="
              additionalFields && additionalFields.flat == 2 && !privateHouse
            "
            label="Квартира"
            v-model="apart"
            outlined
            :rules="[(v) => !!v || 'Укажите квартиру']"
            hide-details
            clearable
            type="number"
          />
        </div>

        <!-- <v-text-field
          class="mb-4"
          label="Кол-во персон"
          v-model="count_person"
          outlined
          hide-details
          v-if="additionalFields && additionalFields.count_person == 2"
        /> -->

        <QtySelect
          v-if="additionalFields && additionalFields.count_person == 2"
          v-model="count_person"
          class="mb-4"
        />

        <template
          v-if="
            useGeoAddressDelivery &&
            deliveryPrice &&
            !geoError &&
            street &&
            house
          "
        >
          <v-alert text color="error">
            Стоимость доставки для этого адреса {{ deliveryPrice }}
            {{ this.currency }}
          </v-alert>
        </template>

        <div v-if="$store.getters['user/isAuthorized']">
          <v-switch
            v-model="saveAddress"
            label="Сохранить адрес для будущих заказов"
            inset
          />
        </div>
      </section>

      <section v-if="isPickup && pickupPoints.length" class="mb-10">
        <div class="heading mb-6">Самовывоз</div>

        <v-select
          class="mb-4"
          label="Точка самовывоза"
          v-model="pickupPoint"
          :items="pickupPoints"
          outlined
          :rules="[(v) => !!v || 'Выберите точку самовывоза']"
          hide-details
        >
          <template v-slot:append>
            <v-icon color="secondary">{{ icons.mdiDotsVertical }}</v-icon>
          </template>
        </v-select>

        <QtySelect
          v-if="additionalFields && additionalFields.count_person == 2"
          v-model="count_person"
          class="mb-4"
        />
      </section>

      <section v-if="deliveryType === 'dinein'" class="mb-10">
        <div class="heading mb-6">Заказ в заведении</div>

        <v-text-field
          class="mb-4"
          label="Номер стола"
          v-model="table"
          outlined
          :rules="[(v) => !!v || 'Укажите номер стола']"
          hide-details
        />

        <div v-if="!!pickupPointsEstablishment.length">
          <div class="heading mb-6">Выберите заведение</div>

          <v-select
            class="mb-4"
            label="Выберите заведение"
            v-model="pickupPoint"
            :items="pickupPointsEstablishment"
            :rules="[(v) => !!v || 'Укажите заведение']"
            outlined
            hide-details
          >
            <template v-slot:append>
              <v-icon color="secondary">{{ icons.mdiDotsVertical }}</v-icon>
            </template>
          </v-select>
        </div>
        <!-- <v-text-field
          class="mb-4"
          label="Кол-во персон"
          v-model="person"
          outlined
          :rules="[(v) => !!v || 'Укажите кол-во персон']"
          hide-details
        /> -->

        <QtySelect
          v-if="additionalFields && additionalFields.count_person == 2"
          v-model="count_person"
          class="mb-4"
        />
      </section>

      <section
        v-if="Array.isArray(dateList) && dateList.length > 0"
        class="mb-10"
      >
        <div v-if="!$store.getters['app/isQrMenu']" class="heading mb-6">
          Время и дата
        </div>

        <v-switch
          v-if="!$store.getters['app/isQrMenu']"
          v-model="asap"
          label="Приготовить как можно быстрее"
          class="mb-4"
          inset
        />

        <template v-if="!asap">
          <v-select
            class="mb-4"
            :label="dateLabel"
            v-model="deliveryDate"
            :items="dateList"
            outlined
            :rules="[(v) => !!v || 'Укажите дату']"
            hide-details
          >
            <template v-slot:append>
              <v-icon color="secondary">{{ icons.mdiDotsVertical }}</v-icon>
            </template>
          </v-select>

          <v-select
            class="mb-4"
            :label="timeLabel"
            v-model="deliveryTime"
            :items="timeList"
            outlined
            :rules="[(v) => !!v || 'Укажите время']"
            hide-details
          >
            <template v-slot:append>
              <v-icon color="secondary">{{ icons.mdiDotsVertical }}</v-icon>
            </template>
          </v-select>
        </template>
      </section>

      <section class="mb-10">
        <div class="heading mb-6">Контактные данные</div>

        <v-text-field
          class="mb-4"
          label="Ваше имя"
          v-model="name"
          outlined
          :rules="[(v) => !!v || 'Укажите имя']"
          hide-details
        />

        <v-text-field
          class="mb-4"
          label="Телефон для связи"
          v-model="phone"
          outlined
          v-mask="phoneMask"
          type="tel"
          :rules="phoneRules"
          hide-details
          :readonly="$store.getters['app/alternativeAuth']"
        />
      </section>

      <section class="mb-10">
        <div class="heading mb-6">Оплата</div>
        <v-select
          class="mb-4"
          label="Способ оплаты"
          v-model="paymentType"
          :items="paymentList"
          outlined
          :rules="[(v) => !!v || 'Выберите способ оплаты']"
          hide-details
        >
          <template v-slot:append>
            <v-icon color="secondary">{{ icons.mdiDotsVertical }}</v-icon>
          </template>
          <template #item="{ item: payment }">
            <span>{{ payment.text }}</span>
            <v-spacer />
            <img
              v-if="payment.value === 'gpay'"
              src="@/assets/images/payment/google-pay-mark_800.svg"
              alt=""
              width="82"
              :style="{ marginRight: '-20px' }"
            />
            <img
              v-else-if="payment.value === 'apay'"
              src="@/assets/images/payment/Apple_Pay_Mark_RGB_041619.svg"
              alt=""
              width="44"
            />
            <img
              v-else-if="payment.value === 'pyr'"
              src="@/assets/images/payment/credit-card-2-back.svg"
              alt=""
              width="32"
            />
            <img
              v-else-if="payment.value === 'cod'"
              src="@/assets/images/payment/wallet2.svg"
              alt=""
              width="32"
            />
            <img
              v-else
              src="@/assets/images/payment/photo.png"
              alt=""
              width="100"
            />
          </template>
        </v-select>
        <template v-if="orderEnsureLabel && !$store.getters['app/isQrMenu']">
          <div class="mt-2">
            <v-switch
              v-model="orderEnsure"
              :label="orderEnsureLabel"
              class="mb-4"
              inset
            />
          </div>
        </template>
      </section>

      <section v-if="paymentType && hasChange" class="mb-4">
        <div class="heading mb-6">С какой суммы подготовить сдачу?</div>
        <v-text-field
          class="mb-4"
          id="change-input"
          label="С какой суммы подготовить сдачу?"
          v-model="changeOrder"
          outlined
          hide-details
          @input.native="onChangeInput($event)"
        />
      </section>

      <section class="mb-4">
        <div class="heading mb-6">Дополнительно</div>
        <v-textarea
          v-model="comment"
          outlined
          label="Ваш комментарий к заказу"
        ></v-textarea>
      </section>
    </v-form>

    <v-alert
      v-if="cartErrors && cartErrors.length"
      text
      prominent
      type="warning"
      class="mb-4"
    >
      <p v-for="(error, i) in cartErrors" :key="i" class="mb-2">{{ error }}</p>
    </v-alert>
  </v-container>
</template>

<script>
import DOMPurify from 'dompurify'
import { mdiDotsVertical, mdiChevronRight } from '@mdi/js'
import { mapState, mapGetters, mapMutations } from 'vuex'
import actionButtonMixin from '@/mixins/actionButton'
import checkoutFields from '@/mixins/checkoutFields'
import { cordovaPostMessage } from '@/plugins/cordova'
import QtySelect from '@/components/QtySelect'

const { cordova } = window

export default {
  mixins: [actionButtonMixin, checkoutFields],

  components: {
    QtySelect,
  },

  data: () => ({
    icons: {
      mdiDotsVertical,
      mdiChevronRight,
    },
    address: null,
    phoneRules: [
      (v) => (v && v.replace(/\D+/g, '').length >= 11) || 'Укажите телефон',
    ],
    loading: false,
    paymentToken: '',
    saveAddress: false,
    pickupPointsEstablishment: [],
  }),

  computed: {
    actionButton() {
      return {
        active: true,
        action: this.submit,
        text: `Оформить | ${this.cartTotalPrice} ${this.currency}`,
        loading: this.loading,
        disabled: this.geoError || !!this.cartErrors.length,
        isGooglePay: this.googlePayPicked,
        isApplePay: this.applePayPicked,
      }
    },
    orderEnsureLabel() {
      return this.$store.getters['app/orderEnsureLabel']
    },
    hasChange() {
      if (!this.paymentType) return false
      return !!this.paymentList?.find?.(
        (item) => item?.value === this.paymentType
      )?.change
    },

    cartErrors() {
      return this.cart?.cart_error
    },

    isPickup() {
      return this.deliveryType === 'pickup'
    },

    isDelivery() {
      return this.deliveryType === 'delivery'
    },

    isDinein() {
      return this.deliveryType === 'dinein'
    },

    dateLabel() {
      return `Дата ${this.isPickup ? 'самовывоза' : 'доставки'}`
    },

    timeLabel() {
      return `Время ${this.isPickup ? 'самовывоза' : 'доставки'}`
    },

    extendedAddress() {
      const arr = this.fields.addressLine.split(', ')
      const index = arr.indexOf(this.fields.street)
      arr.splice(index, arr.length)
      return arr.join(', ')
    },

    currency() {
      return this.$store.getters['app/currency']
    },

    ...mapGetters('checkout', [
      'cartTotalPrice',
      'deliveryType',
      'deliveryPrice',
      'pointsApply',
      'geoError',
      'cartItems',
    ]),

    ...mapGetters('app', [
      'phoneMask',
      'phonePrefix',
      'useGeoAddressDelivery',
      'additionalFields',
      'successOrderMsg',
    ]),

    ...mapState('checkout', [
      'cart',
      'deliveryTypes',
      'dateList',
      'timeList',
      'pickupPoints',
      'fields',
    ]),

    ...mapState('user', ['addressBook', 'addressDetail']),

    addresses() {
      const book = this.addressBook
      return (
        book &&
        book.length &&
        book.map((item) => ({
          text: item.location_name,
          value: item,
        }))
      )
    },

    paymentList() {
      return this.$store.state.checkout.paymentList?.filter((payment) => {
        const platform = localStorage.getItem('platform') || ''

        if (platform.toLowerCase() === 'ios' && payment.value === 'gpay') {
          return false
        }

        if (platform.toLowerCase() === 'android' && payment.value === 'apay') {
          return false
        }

        return true
      })
    },

    googlePayPicked() {
      return this.paymentType === 'gpay'
    },

    applePayPicked() {
      return this.paymentType === 'apay'
    },

    sbpPicked() {
      return (
        this.paymentType === 'sbp_sberbank' ||
        this.paymentType === 'sbp_tinkoff'
      )
    },

    gPayOptions() {
      return this.$store.state.app.settings.paymentGpay || {}
    },

    aPayOptions() {
      return this.$store.state.app.settings.paymentApay || {}
    },

    privateHouse: {
      get() {
        return this.fields.apart === 'Частный дом'
      },
      set(value) {
        if (value) {
          this.$store.commit('checkout/setField', {
            name: 'apart',
            value: 'Частный дом',
          })
        } else {
          this.$store.commit('checkout/setField', {
            name: 'apart',
            value: '',
          })
        }
      },
    },

    addressLine() {
      return this.fields.addressLine
    },
  },

  methods: {
    ...mapMutations('address', ['SET_ACTIVE']),
    ...mapMutations('checkout', ['setField', 'setGeoError']),

    validate() {
      return this.$refs.form.validate()
    },

    filterChangeValue(e) {
      let expect = e.target.value.toString() + e.key.toString()

      if (!/^[0-9]*\.?[0-9]*$/.test(expect)) {
        e.preventDefault()
      } else {
        return true
      }
    },

    checkProfile() {
      const user = this.$store.state.user
      const profile = user && user.profile
      if (profile) {
        if (!this.$store.state.checkout.fields.name) {
          this.name = profile.full_name?.trim() || profile.first_name
        }
        if (!this.$store.state.checkout.fields.phone) {
          this.phone = profile.contact_phone
        }
      }
    },

    orderMsg(orderID) {
      const testReg = /%s/gim
      return this.successOrderMsg &&
        [...new Set(Array.from(this.successOrderMsg))].length - 1
        ? DOMPurify.sanitize(
            this.successOrderMsg?.replaceAll?.(testReg, orderID) ||
              `Спасибо, ваш заказ №${orderID} оформлен`
          )
        : `Спасибо, ваш заказ №${orderID} оформлен`
    },

    async saveAddressBook() {
      try {
        await this.$store.dispatch('user/saveAddress', {
          location_name: [this.street, this.house]
            .filter((v) => !!v)
            .join(', '),
          street: [this.$store.getters['app/city'], this.street]
            .filter(Boolean)
            .join(', '),
          state: this.house,
          zipcode: this.apart,
          pod: this.pod,
          et: this.et,
        })
      } catch {
        /*  */
      }
    },

    async submit() {
      const { commit, dispatch } = this.$store

      if (this.isDelivery && (!this.fields.street || !this.fields.house)) {
        commit('snackbar/update', { text: 'Укажите дом и улицу', active: true })
        return
      }

      try {
        commit('overlay/update', { active: true })
        if (this.cart?.cart_error?.length) {
          commit('snackbar/update', {
            active: true,
            text: this.cart.cart_error[0],
          })
          return
        }

        if (!this.validate()) {
          commit('snackbar/update', {
            active: true,
            text: 'Пожалуйста, заполните все необходмые поля',
          })
          return
        } else if (this.deliveryType === 'delivery' && !this.fields.street) {
          commit('snackbar/update', {
            active: true,
            text: 'Укажите адрес доставки',
          })
          return
        }

        if (
          (this.googlePayPicked || this.applePayPicked) &&
          !this.paymentToken
        ) {
          this.callNativePay()
          return
        }

        this.loading = true

        if (this.isDelivery) {
          if (!(await this.setAddress())) {
            this.loading = false
            return
          }
        }

        const params = {
          transaction_type: this.deliveryType,
          delivery_asap: this.asap,
          delivery_date: this.deliveryDate,
          contact_name: this.name,
          contact_phone: this.phone,
          payment_provider: this.paymentType,
          delivery_instruction: this.comment,
          comment: this.comment,
        }

        if (this.orderEnsure || this.orderEnsureLabel) {
          params.call_me = this.orderEnsure
        }

        if (
          this.paymentToken &&
          (this.googlePayPicked || this.applePayPicked)
        ) {
          params.payment_token = this.paymentToken
        }

        if (!this.asap) {
          params.delivery_time = this.deliveryTime
        }

        if (this.deliveryType === 'dinein') {
          params.dinein_number_of_guest = this.count_person
          params.dinein_special_instruction = this.comment
          params.dinein_table_number = this.table
          params.point_delivery = this.pickupPoint
        }

        if (this.deliveryType === 'pickup') {
          params.point_delivery = this.pickupPoint
          params.count_person = this.count_person
        }

        if (this.changeOrder) {
          params.order_change = this.changeOrder
        }

        const result = await dispatch('checkout/createOrder', params)
        this.loading = false

        if (result.code != 1) {
          const text = result.msg || 'Возникла ошибка'
          commit('snackbar/update', { active: true, text })
          return
        }

        const coupon =
          (this.cart.cart_details.voucher_details &&
            JSON.parse(this.cart.cart_details.voucher_details)) ||
          {}
        const orderId = result.details?.order_id

        this.$tracardi.track('order-started', {
          id: orderId,
          products: this.cartItems.map((item) => ({
            id: item.item_id,
            name: item.item_name,
            price: item.discounted_price || item.normal_price,
            quantity: item.qty,
            ...(item.size_words && {
              variant: {
                name: item.size_words,
              },
            }),
          })),
          coupon: coupon?.voucher_name,
          cost: {
            discount: this.basket_discount,
            shipping: this.deliveryPrice,
          },
          income: {
            revenue: this.cart.basket_total_with_discount,
            value: this.cartTotalPrice,
          },
          utm: {
            source: sessionStorage.getItem('utm_source'),
            medium: sessionStorage.getItem('utm_medium'),
            campaign: sessionStorage.getItem('utm_campaign'),
          },
          name: this.name,
          phone: this.phone?.replace(/[^\d+]+/g, ''),
        })

        if (this.saveAddress && this.isDelivery) {
          this.saveAddressBook()
        }

        const redirectURL = result?.details?.redirect_url

        if (redirectURL) {
          if (window.browser) {
            if (this.sbpPicked) {
              this.$router.push({
                name: 'Order',
                query: { id: result.details?.order_id, redirect: redirectURL },
              })
            } else {
              try {
                cordovaPostMessage({
                  type: 'open',
                  data: {
                    url: redirectURL,
                    target: '_blank',
                  },
                })
              } catch {
                window.location.href = redirectURL
              }
            }
          } else {
            const payment = cordova.InAppBrowser.open(
              redirectURL,
              '_blank',
              'location=no'
            )

            /* payment.addEventListener("message", (e) => {
              console.log(e);
            }); */

            payment.addEventListener('loadstop', (event) => {
              // console.log(event);

              const url = event.url

              const success = url?.match(/success/gi)
              const cancel = url?.match(/cancel/gi)
              const error = url?.match(/error/gi)

              if (success) {
                payment.close()
                this.$router.push({
                  name: 'PaymentSuccess',
                  query: { id: result.details?.order_id || 0 },
                })
              }

              if (cancel) {
                payment.close()
              }

              if (error) {
                payment.close()
              }
            })
          }
        } else {
          setTimeout(() => {
            this.$tracardi.track('order-completed', {
              order: {
                id: orderId,
                status: 'completed',
              },
            })
          }, 200)

          commit('snackbar/update', {
            active: true,
            text: this.orderMsg(result.details?.order_id),
          })
          this.$router.push({ name: 'Home' })
        }
      } catch (e) {
        alert(e.message)
      } finally {
        commit('overlay/update', { active: false })
        await dispatch('checkout/loadCart', this.deliveryType)
        this.loading = false
      }
    },

    callNativePay() {
      const data = {
        amount: this.cartTotalPrice,
        //amount: 1,
        countryCode: 'RU',
        currencyCode: 'RUB',
        purpose: this.$store.state.app.info?.data?.merchant_name,
      }

      if (this.googlePayPicked) {
        data.merchantId = this.gPayOptions.merchantId
        data.gateway = this.gPayOptions.gateway
        data.gpMerchantName = this.gPayOptions.merchantName
        data.gpMerchantId = this.gPayOptions.gatewayMerchantId
      } else if (this.applePayPicked) {
        data.merchantId = this.aPayOptions.merchantId
      }

      try {
        cordovaPostMessage({
          type: 'payment',
          data: {
            request: data,
          },
        })
      } catch {
        alert('Выбранный способ оплаты не доступен')
      }
    },

    async setAddress() {
      const params = {
        street: [this.$store.state.checkout.fields?.addressCity, this.street]
          .filter(Boolean)
          .join(', '),
        state: this.house,
        zipcode: this.apart,
        transaction_type: this.deliveryType,
        contact_phone: this.phone,
        contact_name: this.name,
        delivery_instruction: this.comment,
        pod: this.pod,
        et: this.et,
        domofon: this.domofon,
        count_person: this.count_person,
        housing: this.housing,
      }
      return await this.$store.dispatch('checkout/setDeliveryAddress', params)
    },

    async addressGeocode() {
      const {
        useGeoAddressDelivery,
        $store: { commit, dispatch },
      } = this
      if (this.fields.street && this.fields.house && useGeoAddressDelivery) {
        commit('overlay/setFetching', true)
        commit('overlay/update', { active: true })
        const params = { query: this.fields.addressLine }
        const response = await dispatch('checkout/geoAdressDelivery', params)
        await dispatch('checkout/loadCart')
        commit('overlay/setFetching', false)
        commit('overlay/update', { active: false })
        if (response?.msg == 'error') {
          const text = response.details?.text
          commit('snackbar/update', { active: true, text })
          this.setGeoError(true)
        } else {
          this.setGeoError(false)
        }
      }
    },

    async addressSelect() {
      const { commit } = this.$store

      try {
        commit('overlay/update', { active: true })
        await this.loadAddress(this.address.id)

        const street = this.addressDetail?.street.split(', ')[1]

        this.setField({
          name: 'addressLine',
          value:
            this.addressDetail?.address_line ||
            [this.addressDetail?.street, this.addressDetail?.state]
              .filter(Boolean)
              .join(', '),
        })
        this.setField({ name: 'street', value: street })
        this.setField({ name: 'house', value: this.addressDetail?.state })
        this.setField({ name: 'apart', value: this.addressDetail?.zipcode })
        this.setField({ name: 'et', value: this.addressDetail?.et })
        this.setField({ name: 'pod', value: this.addressDetail?.pod })
        this.setField({ name: 'housing', value: this.addressDetail?.housing })
        this.setField({ name: 'domofon', value: this.addressDetail?.domofon })

        if (this.fields.street && this.fields.house) {
          await this.addressGeocode()
        }

        this.SET_ACTIVE(true)
      } catch (error) {
        commit('snackbar/update', {
          active: true,
          text: error.message,
        })
      } finally {
        commit('overlay/update', { active: false })
      }
      // return;

      // this.name = this.addressDetail?.location_name;
    },

    async loadAddress(id) {
      const { dispatch } = this.$store
      await dispatch('user/loadAddress', id)
    },

    onChangeInput(e) {
      const transformedVal = e.target.value.replace(/[^0-9]/g, '')
      e.target.value = transformedVal
      this.changeOrder = transformedVal
    },
  },

  watch: {
    deliveryDate(value) {
      this.$store.dispatch('checkout/loadTimeList', value)
    },

    street(v) {
      if (v != this.addressDetail?.street.split(', ')[1]) {
        this.address = null
      }
    },

    phone(value) {
      const { phoneMask, phonePrefix } = this
      const { VMask } = this.$options.filters

      const phoneMaskLength = phoneMask?.toString().replace(/[^#]/g, '').length
      const phonePrefixLength = phonePrefix
        ?.toString()
        .replace(/\D+/g, '').length

      let number = value.replace(/\D+/g, '')

      if (
        number.length > phoneMaskLength + phonePrefixLength &&
        (number[phonePrefixLength] === '7' || number[phonePrefixLength] === '8')
      ) {
        number = number.substring(1 + phonePrefixLength)
        this.$nextTick(() => {
          this.phone = VMask(number, phoneMask)
        })
      }
    },

    addressLine() {
      if (this.isDelivery) {
        this.addressGeocode()
      }
    },

    isDelivery(value) {
      if (value) {
        this.addressGeocode()
      } else if (this.geoError) {
        this.setGeoError(false)
      }
    },

    async deliveryTime() {
      const { commit, dispatch } = this.$store
      commit('overlay/update', { active: true })
      await dispatch('checkout/loadCart', this.deliveryType)
      commit('overlay/update', { active: false })
    },

    async asap(value) {
      if (value || (!value && this.deliveryTime)) {
        const { commit, dispatch } = this.$store
        commit('overlay/update', { active: true })
        await dispatch('checkout/loadCart', this.deliveryType)
        commit('overlay/update', { active: false })
      }
    },
  },

  async created() {
    const { commit, dispatch } = this.$store

    window.addEventListener('cordovaEvent', (event) => {
      const { name: eventName, data: eventData } = event.detail

      switch (eventName) {
        case 'push':
          // console.log("Cordova event PUSH", eventData);
          break

        case 'toast':
          if (eventData.message) {
            this.$store.commit('snackbar/update', {
              active: true,
              text: eventData.message,
            })
          }
          break

        case 'paymentToken':
          this.paymentToken = eventData.value
          this.submit()
          break

        default:
          break
      }
    })

    try {
      if (!(this.deliveryType && this.cartTotalPrice)) {
        this.$router.replace({ name: 'Cart' })
        return
      }

      commit('overlay/update', { active: true })

      const promises = [
        dispatch('checkout/loadCart', this.deliveryType),
        dispatch('checkout/loadDateList'),
        dispatch('checkout/loadPaymentList'),
      ]
      if (this.isPickup || this.isDinein) {
        promises.push(dispatch('checkout/loadPickupPoints'))
      }

      if (this.isDelivery) {
        promises.push(dispatch('user/loadAddressBook'))
      }

      await Promise.all(promises)

      if (!this.deliveryDate) {
        this.deliveryDate = this.cart.default_delivery_date
      }

      this.pickupPointsEstablishment = this.pickupPoints.filter(
        (point) => point.is_dine_in
      )

      if (this.isPickup && !this.pickupPoint) {
        const defaultPoint = this.pickupPoints.find((point) => point.default)
        if (defaultPoint) {
          commit('checkout/setField', {
            name: 'pickupPoint',
            value: defaultPoint.value,
          })
        } else if (this.pickupPoints.length === 1) {
          commit('checkout/setField', {
            name: 'pickupPoint',
            value: this.pickupPoints[0].value,
          })
        }
      }

      if (this.isDelivery && this.fields.street && this.fields.house) {
        await this.addressGeocode()
      }

      if (!this.isDelivery && this.geoError) {
        this.setGeoError(false)
      }

      this.checkProfile()
    } catch (e) {
      alert(e.message)
    } finally {
      commit('overlay/update', { active: false })
    }
  },

  async mounted() {
    document.querySelector('title').innerText = 'Оформление заказа'

    if (
      (!this.fields.street && this.isDelivery) ||
      (this.deliveryType !== 'delivery' && !this.fields.pickupPoint)
    ) {
      this.SET_ACTIVE(true)
    }

    await this.$nextTick()
    const actionBtn = document.querySelector('.action-btn-wrapper')
    if (actionBtn) {
      actionBtn.classList.add('express-submit-cart')
    }
  },
}
</script>

<style scoped>
.theme--dark.v-list {
  background-color: #fff;
}
>>> .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #000 !important;
}

h2 {
  font-weight: bold;
  font-size: 18px;
  margin: 0 0 10px;
}

.fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.extended-address {
  font-size: 14px;
  opacity: 0.6;
}
</style>
