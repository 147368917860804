const mixin = {
  computed: {},
}

const fields = [
  'name',
  'phone',
  'street',
  'house',
  'apart',
  'housing',

  'et',
  'pod',
  'domofon',
  'count_person',

  'asap',
  'deliveryDate',
  'deliveryTime',

  'paymentType',
  'comment',
  'pickupPoint',

  'table',
  'person',
  'changeOrder',
  'orderEnsure',
]

fields.forEach((name) => {
  mixin.computed[name] = {
    get() {
      return this.$store.state.checkout.fields[name]
    },
    set(value) {
      const { commit } = this.$store
      commit('checkout/setField', { name, value })
    },
  }
})

export default mixin
